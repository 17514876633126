/* Applies to our current 'standard' button look. Gives a button the correct sizing, text, shape, etc. */
.standard-rounded-button {
	@apply w-full cursor-pointer whitespace-nowrap rounded-full px-12 py-5 text-center text-xl font-semibold tracking-tight;
}

/* Blue button with white text */
.solid-button {
	@apply bg-sky-600 text-white active:bg-sky-700 [@media(hover:hover)]:hover:bg-sky-700;
}

/* White button with grey border and text */
.subtle {
	@apply cursor-pointer rounded-full border-2 border-slate-300 bg-white text-slate-400 active:bg-sky-700 active:text-white [@media(hover:hover)]:hover:border-transparent [@media(hover:hover)]:hover:bg-sky-700 [@media(hover:hover)]:hover:text-white;
}

/* Transparent button with white border */
.transparent {
	@apply cursor-pointer rounded-full border-2 border-white bg-transparent text-white hover:bg-white hover:text-black active:bg-white active:text-black [@media(hover:hover)]:hover:border-white [@media(hover:hover)]:hover:bg-white [@media(hover:hover)]:hover:text-black;
}

/* pink to orange gradient button with white text */
.gradient-button {
	@apply bg-gradient-160 from-persimmon via-vivid-cerise via-40% to-blue-ncs tracking-tight text-white active:from-blue-ncs active:via-vivid-cerise active:via-60% active:to-persimmon [@media(hover:hover)]:hover:from-blue-ncs [@media(hover:hover)]:hover:via-vivid-cerise [@media(hover:hover)]:hover:via-60% [@media(hover:hover)]:hover:to-persimmon;
}

/* Small text-only button */
.text-button {
	@apply whitespace-nowrap text-center font-normal text-inherit text-sky-700 active:text-sky-800 [@media(hover:hover)]:hover:text-sky-800;
}

.text-button-disabled {
	@apply text-slate-400;
}

/* These are currently only used for the filter buttons on the Blog Landing page */
/* Small rounded bordered button that changes styles when checked */
.filter-button {
	@apply block cursor-pointer whitespace-nowrap rounded-full px-5 py-3 text-sm font-semibold tracking-tight lg:px-10 lg:text-base;
	@apply border-2 border-sky-700 bg-white text-sky-700 active:bg-sky-700 active:text-white [@media(hover:hover)]:hover:border-transparent [@media(hover:hover)]:hover:bg-sky-700 [@media(hover:hover)]:hover:text-white;
}

.filter-button-checked {
	@apply !bg-sky-700 !text-white;
}

/**
 * Sizes
 */
.large {
	@apply px-8 py-4 text-base font-semibold lg:px-10 lg:py-5 lg:text-xl lg:tracking-tighter;
}
.medium {
	@apply px-8 py-4 text-base font-semibold;
}
.medsm {
	@apply px-4 py-2 text-base font-semibold lg:px-8 lg:py-4;
}
.small {
	@apply px-4 py-2 text-base font-semibold;
}

/**
 * Default
 */
.default {
	@apply cursor-pointer rounded-full bg-sky-600 text-center text-white  active:bg-sky-700 [@media(hover:hover)]:hover:bg-sky-700;
}

/**
 * Gradient
 */
.primary-gradient {
	@apply cursor-pointer rounded-full bg-gradient-160 from-persimmon via-vivid-cerise via-40% to-blue-ncs text-center tracking-tight text-white active:from-blue-ncs active:via-vivid-cerise active:via-60% active:to-persimmon [@media(hover:hover)]:hover:from-blue-ncs [@media(hover:hover)]:hover:via-vivid-cerise [@media(hover:hover)]:hover:via-60% [@media(hover:hover)]:hover:to-persimmon;
}

/**
 * Outline
 */
.outline {
	@apply cursor-pointer rounded-full border-2 border-sky-700 bg-white text-center tracking-normal text-sky-700 active:bg-sky-700 active:text-white [@media(hover:hover)]:hover:border-transparent [@media(hover:hover)]:hover:bg-sky-700 [@media(hover:hover)]:hover:text-white;
}

/**
* Transparent Outline
* Named like this in order to avoid overlap with .outline and .transparent styles
*/
.border-no-bg {
	@apply cursor-pointer rounded-full border-2 border-sky-700 bg-transparent text-center tracking-normal text-sky-700 active:bg-sky-700 active:text-white [@media(hover:hover)]:hover:border-transparent [@media(hover:hover)]:hover:bg-sky-700 [@media(hover:hover)]:hover:text-white;
}

/**
 * Disabled
 */
:disabled.disabled {
	@apply cursor-not-allowed bg-slate-300;
}

/**
 * Focus
 */
.button-focus-styles {
	@apply focus:ring-2 focus-visible:ring-2;
}
